import { render, staticRenderFns } from "./expenses-by-country.vue?vue&type=template&id=efd6d782&"
import script from "./expenses-by-country.vue?vue&type=script&lang=js&"
export * from "./expenses-by-country.vue?vue&type=script&lang=js&"
import style0 from "./expenses-by-country.vue?vue&type=style&index=0&id=efd6d782&prod&lang=scss&module=true&"




function injectStyles (context) {
  
  this["$style"] = (style0.locals || style0)

}

/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
